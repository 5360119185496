.billing-page-container {
    text-align: center;
    margin: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  p {
    font-size: 1.2em;
    margin-bottom: 20px;
  }
  
  .iframe-container {
    width: 100%;
    height: 80vh; /* Adjust height as needed */
    overflow: hidden;
    position: relative;
  }
  
  .billing-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  