/*   Makes the footer sticky at the bottom of the page  */
.footer {
  position: flex;
  bottom: 0;
  width: 100%;
  background-color: #f5f5f5;
  text-align: center;
  padding: 1px 0;
  font-size: 12px;
}

/* Navigation links inside footer */
.footer-nav {
  margin-top: 10px; /* Space above navigation links */
}

/* Individual link styling */
.footer-link {
  color: #0066cc; /* Standard link color for visibility */
  text-decoration: none; /* No underline by default */
  margin: 0 10px; /* Space between links */
}


/* Hover state for links */
.footer-link:hover {
  text-decoration: underline; /* Underline on hover for better user interaction */
}