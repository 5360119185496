
.bgimg {
  /* Background image */
  background-image: url('/src/assets/background.jpeg');
  /* Full-screen */
  height: 100%;
  /* Center the background image */
  background-position: center;
  /* Scale and zoom in the image */
  background-size: cover;
  /* Add position: relative to enable absolutely positioned elements inside the image (place text) */
  position: relative;
  /* Add a white text color to all elements inside the .bgimg container */
  color: white;
  /* Add a font */
  font-family: "Courier New", Courier, monospace;
  /* Set the font-size to 18 pixels */
  font-size: 18px;
  /* Center the content inside the bgimg */
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 100vh;  /* Ensures at least full viewport height */
  height: auto;    /* Allows container to grow with more content */
}

.services-content {

  background-color: rgba(0, 0, 0, 0.7);
   padding: 1em;
  border-radius: 8px;
  text-align: center;
  color: white;
  max-width: 1200px;
  width: 100%;
}

.services-content h2 {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px; /* Adjusting font size */
}

.services-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.service-box {
  background-color: rgba(255, 255, 255, 0.9);
  color: black;
  border-radius: 8px;
  padding: 20px;
  width: 280px; /* Ensure all boxes are the same width */
  height: 350px; /* Ensure all boxes are the same height */
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Evenly space the content vertically */
  align-items: center; /* Center the content horizontally */
}

.service-box img {
  width: 100px; /* Adjusting image size */
  height: 100px; /* Adjusting image size */
  object-fit: cover;
  margin-bottom: 1em;
}

.service-box h3 {
  margin-bottom: 0.5em;
  font-size: 20px; /* Adjusting font size */
}

.service-box p {
  font-size: 16px; /* Adjusting font size */
}

.apps-coming-soon-grid {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  margin-top: 2em;
}

.apps-box, .coming-soon-box {
  flex: 1;
}

.service-box a {
  color: black;
  text-decoration: none;
}

.service-box a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .services-grid, .apps-coming-soon-grid {
    flex-direction: column;
    align-items: center;
  }
  .service-box {
    width: 90%;
    margin-bottom: 1em;
  }
}
