/* Home.css - Updated to create a more professional look */

body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  background: url('assets/background.jpeg') no-repeat center center;
  background-size: cover;
  color: #333;
}

.App {
  text-align: center;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}

.navbar-brand {
  font-size: 1.5em;
  font-weight: bold;
}

.navbar-menu {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-menu li {
  margin: 0 1em;
}

.navbar-menu li a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.home {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.home-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #007bff;
  color: white;
  padding: 2rem;
  border-radius: 10px;
  min-height: 150px;
  text-align: center;
  width: 100%;
  margin: 2rem auto;
  box-sizing: border-box;
}


.home-header p {
  font-size: 1.2rem;
  margin: 0.5rem auto 0;
  text-align: center;
}

.home-services {
  margin-top: 3rem;
  padding: 2rem;
  background-color: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.home-header h1 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #ffffff;
  text-align: center;
}

.home-services h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #007bff;
  text-align: center;
}

.home-services ul {
  list-style-type: none;
  padding: 0;
}

.home-services li {
  font-size: 1.1rem;
  margin-bottom: 1rem;
  line-height: 1.5;
  padding-left: 1.5rem;
  position: relative;
}

.home-services li:before {
  content: '\2022';
  color: #007bff;
  font-weight: bold;
  display: inline-block;
  width: 1rem;
  margin-left: -1.5rem;
}

.home-contact {
  margin-top: 3rem;
  text-align: center;
  padding: 2rem;
  background-color: #f1f1f1;
  border-radius: 10px;
}

.home-contact h2 {
  font-size: 2rem;
  color: #007bff;
  margin-bottom: 1rem;
}

.home-contact p {
  font-size: 1.2rem;
}

.home-contact a {
  color: #007bff;
  font-weight: bold;
  text-decoration: none;
  border-bottom: 1px solid #007bff;
  transition: all 0.3s;
}

.home-contact a:hover {
  color: #0056b3;
  border-bottom: 1px solid #0056b3;
}

@media (max-width: 768px) {
  .home-header h1 {
    font-size: 2rem;
  }

  .home-header p {
    font-size: 1rem;
  }

  .home-services h2, .home-contact h2 {
    font-size: 1.5rem;
  }

  .home-contact p {
    font-size: 1rem;
  }
}
